export const ADMIN_GET_ALL_USERS_REQUEST = 'ADMIN_GET_ALL_USERS_REQUEST';
export const ADMIN_GET_ALL_USERS_SUCCESS = 'ADMIN_GET_ALL_USERS_SUCCESS';
export const ADMIN_GET_ALL_USERS_FAILURE = 'ADMIN_GET_ALL_USERS_FAILURE';

export const ADMIN_IS_ADMIN_REQUEST = 'ADMIN_IS_ADMIN_REQUEST';
export const ADMIN_IS_ADMIN_SUCCESS = 'ADMIN_IS_ADMIN_SUCCESS';
export const ADMIN_IS_ADMIN_FAILURE = 'ADMIN_IS_ADMIN_FAILURE';

export const ADMIN_IS_SUSPENDED_REQUEST = 'ADMIN_IS_SUSPENDED_REQUEST';
export const ADMIN_IS_SUSPENDED_SUCCESS = 'ADMIN_IS_SUSPENDED_SUCCESS';
export const ADMIN_IS_SUSPENDED_FAILURE = 'ADMIN_IS_SUSPENDED_FAILURE';

export const ADMIN_IS_COACH_REQUEST = 'ADMIN_IS_COACH_REQUEST';
export const ADMIN_IS_COACH_SUCCESS = 'ADMIN_IS_COACH_SUCCESS';
export const ADMIN_IS_COACH_FAILURE = 'ADMIN_IS_COACH_FAILURE';

export const ADMIN_IS_PARENT_REQUEST = 'ADMIN_IS_PARENT_REQUEST';
export const ADMIN_IS_PARENT_SUCCESS = 'ADMIN_IS_PARENT_SUCCESS';
export const ADMIN_IS_PARENT_FAILURE = 'ADMIN_IS_PARENT_FAILURE';

export const ADMIN_IS_PLAYER_REQUEST = 'ADMIN_IS_PLAYER_REQUEST';
export const ADMIN_IS_PLAYER_SUCCESS = 'ADMIN_IS_PLAYER_SUCCESS';
export const ADMIN_IS_PLAYER_FAILURE = 'ADMIN_IS_PLAYER_FAILURE';

export const ADMIN_ALL_PROFILES_REQUEST = 'ADMIN_ALL_PROFILES_REQUEST';
export const ADMIN_ALL_PROFILES_SUCCESS = 'ADMIN_ALL_PROFILES_SUCCESS';
export const ADMIN_ALL_PROFILES_FAILURE = 'ADMIN_ALL_PROFILES_FAILURE';

export const ADMIN_CREATE_PROFILE_REQUEST = 'ADMIN_CREATE_PROFILE_REQUEST';
export const ADMIN_CREATE_PROFILE_SUCCESS = 'ADMIN_CREATE_PROFILE_SUCCESS';
export const ADMIN_CREATE_PROFILE_FAILURE = 'ADMIN_CREATE_PROFILE_FAILURE';

export const ADMIN_EDIT_PROFILE_REQUEST = 'ADMIN_EDIT_PROFILE_REQUEST';
export const ADMIN_EDIT_PROFILE_SUCCESS = 'ADMIN_EDIT_PROFILE_SUCCESS';
export const ADMIN_EDIT_PROFILE_FAILURE = 'ADMIN_EDIT_PROFILE_FAILURE';
