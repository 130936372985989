export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_FORGOT_EMAIL_REQUEST = 'USER_FORGOT_EMAIL_REQUEST';
export const USER_FORGOT_EMAIL_SUCCESS = 'USER_FORGOT_EMAIL_SUCCESS';
export const USER_FORGOT_EMAIL_FAILURE = 'USER_FORGOT_EMAIL_FAILURE';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';

export const USER_ADMIN_DETAILS_REQUEST = 'USER_ADMIN_DETAILS_REQUEST';
export const USER_ADMIN_DETAILS_SUCCESS = 'USER_ADMIN_DETAILS_SUCCESS';
export const USER_ADMIN_DETAILS_FAILURE = 'USER_ADMIN_DETAILS_FAILURE';

export const USER_UPDATE_USER_ADMIN_DETAILS_REQUEST =
  'USER_UPDATE_USER_ADMIN_DETAILS_REQUEST';
export const USER_UPDATE_USER_ADMIN_DETAILS_SUCCESS =
  'USER_UPDATE_USER_ADMIN_DETAILS_SUCCESS';
export const USER_UPDATE_USER_ADMIN_DETAILS_FAILURE =
  'USER_UPDATE_USER_ADMIN_DETAILS_FAILURE';

export const GOOGLE_USER_LOGIN_REQUEST = 'GOOGLE_USER_LOGIN_REQUEST';
export const GOOGLE_USER_LOGIN_SUCCESS = 'GOOGLE_USER_LOGIN_SUCCESS';
export const GOOGLE_USER_LOGIN_FAILURE = 'GOOGLE_USER_LOGIN_FAILURE';
export const GOOGLE_USER_LOGOUT = 'GOOGLE_USER_LOGOUT';
