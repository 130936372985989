export const ADMIN_GET_PRIVATE_MESSAGES_REQUEST =
  'ADMIN_GET_PRIVATE_MESSAGES_REQUEST';
export const ADMIN_GET_PRIVATE_MESSAGES_SUCCESS =
  'ADMIN_GET_PRIVATE_MESSAGES_SUCCESS';
export const ADMIN_GET_PRIVATE_MESSAGES_FAILURE =
  'ADMIN_GET_PRIVATE_MESSAGES_FAILURE';

export const ADMIN_CREATE_PRIVATE_MESSAGES_REQUEST =
  'ADMIN_CREATE_PRIVATE_MESSAGES_REQUEST';
export const ADMIN_CREATE_PRIVATE_MESSAGES_SUCCESS =
  'ADMIN_CREATE_PRIVATE_MESSAGES_SUCCESS';
export const ADMIN_CREATE_PRIVATE_MESSAGES_FAILURE =
  'ADMIN_CREATE_PRIVATE_MESSAGES_FAILURE';

export const ADMIN_IS_COMPLETE_PRIVATE_MESSAGES_REQUEST =
  'ADMIN_IS_COMPLETE_PRIVATE_MESSAGES_REQUEST';
export const ADMIN_IS_COMPLETE_PRIVATE_MESSAGES_SUCCESS =
  'ADMIN_IS_COMPLETE_PRIVATE_MESSAGES_SUCCESS';
export const ADMIN_IS_COMPLETE_PRIVATE_MESSAGES_FAILURE =
  'ADMIN_IS_COMPLETE_PRIVATE_MESSAGES_FAILURE';

export const ADMIN_DELETE_PRIVATE_MESSAGES_REQUEST =
  'ADMIN_DELETE_PRIVATE_MESSAGES_REQUEST';
export const ADMIN_DELETE_PRIVATE_MESSAGES_SUCCESS =
  'ADMIN_DELETE_PRIVATE_MESSAGES_SUCCESS';
export const ADMIN_DELETE_PRIVATE_MESSAGES_FAILURE =
  'ADMIN_DELETE_PRIVATE_MESSAGES_FAILURE';

export const ADMIN_EDIT_PRIVATE_MESSAGES_REQUEST =
  'ADMIN_EDIT_PRIVATE_MESSAGES_REQUEST';
export const ADMIN_EDIT_PRIVATE_MESSAGES_SUCCESS =
  'ADMIN_EDIT_PRIVATE_MESSAGES_SUCCESS';
export const ADMIN_EDIT_PRIVATE_MESSAGES_FAILURE =
  'ADMIN_EDIT_PRIVATE_MESSAGES_FAILURE';

export const USER_GET_PRIVATE_MESSAGES_REQUEST =
  'USER_GET_PRIVATE_MESSAGES_REQUEST';
export const USER_GET_PRIVATE_MESSAGES_SUCCESS =
  'USER_GET_PRIVATE_MESSAGES_SUCCESS';
export const USER_GET_PRIVATE_MESSAGES_FAILURE =
  'USER_GET_PRIVATE_MESSAGES_FAILURE';

export const USER_CREATE_REPLY_PRIVATE_MESSAGES_REQUEST =
  'USER_CREATE_REPLY_PRIVATE_MESSAGES_REQUEST';
export const USER_CREATE_REPLY_PRIVATE_MESSAGES_SUCCESS =
  'USER_CREATE_REPLY_PRIVATE_MESSAGES_SUCCESS';
export const USER_CREATE_REPLY_PRIVATE_MESSAGES_FAILURE =
  'USER_CREATE_REPLY_PRIVATE_MESSAGES_FAILURE';
