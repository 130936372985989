export const GET_PUBLIC_NOTICE_REQUEST = 'GET_PUBLIC_NOTICE_REQUEST';
export const GET_PUBLIC_NOTICE_SUCCESS = 'GET_PUBLIC_NOTICE_SUCCESS';
export const GET_PUBLIC_NOTICE_FAILURE = 'GET_PUBLIC_NOTICE_FAILURE';

export const ADMIN_CREATE_PUBLIC_NOTICE_REQUEST =
  'ADMIN_CREATE_PUBLIC_NOTICE_REQUEST';
export const ADMIN_CREATE_PUBLIC_NOTICE_SUCCESS =
  'ADMIN_CREATE_PUBLIC_NOTICE_SUCCESS';
export const ADMIN_CREATE_PUBLIC_NOTICE_FAILURE =
  'ADMIN_CREATE_PUBLIC_NOTICE_FAILURE';

export const ADMIN_EDIT_PUBLIC_NOTICE_REQUEST =
  'ADMIN_EDIT_PUBLIC_NOTICE_REQUEST';
export const ADMIN_EDIT_PUBLIC_NOTICE_SUCCESS =
  'ADMIN_EDIT_PUBLIC_NOTICE_SUCCESS';
export const ADMIN_EDIT_PUBLIC_NOTICE_FAILURE =
  'ADMIN_EDIT_PUBLIC_NOTICE_FAILURE';

export const ADMIN_DELETE_PUBLIC_NOTICE_REQUEST =
  'ADMIN_DELETE_PUBLIC_NOTICE_REQUEST';
export const ADMIN_DELETE_PUBLIC_NOTICE_SUCCESS =
  'ADMIN_DELETE_PUBLIC_NOTICE_SUCCESS';
export const ADMIN_DELETE_PUBLIC_NOTICE_FAILURE =
  'ADMIN_DELETE_PUBLIC_NOTICE_FAILURE';
