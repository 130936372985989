export const ADMIN_GET_GENERAL_INFO_REQUEST = 'ADMIN_GET_GENERAL_INFO_REQUEST';
export const ADMIN_GET_GENERAL_INFO_SUCCESS = 'ADMIN_GET_GENERAL_INFO_SUCCESS';
export const ADMIN_GET_GENERAL_INFO_FAILURE = 'ADMIN_GET_GENERAL_INFO_FAILURE';

export const ADMIN_CREATE_GENERAL_INFO_REQUEST =
  'ADMIN_CREATE_GENERAL_INFO_REQUEST';
export const ADMIN_CREATE_GENERAL_INFO_SUCCESS =
  'ADMIN_CREATE_GENERAL_INFO_SUCCESS';
export const ADMIN_CREATE_GENERAL_INFO_FAILURE =
  'ADMIN_CREATE_GENERAL_INFO_FAILURE';

export const ADMIN_EDIT_GENERAL_INFO_REQUEST =
  'ADMIN_EDIT_GENERAL_INFO_REQUEST';
export const ADMIN_EDIT_GENERAL_INFO_SUCCESS =
  'ADMIN_EDIT_GENERAL_INFO_SUCCESS';
export const ADMIN_EDIT_GENERAL_INFO_FAILURE =
  'ADMIN_CREATE_GENERAL_INFO_FAILURE';

export const ADMIN_DELETE_GENERAL_INFO_REQUEST =
  'ADMIN_DELETE_GENERAL_INFO_REQUEST';
export const ADMIN_DELETE_GENERAL_INFO_SUCCESS =
  'ADMIN_DELETE_GENERAL_INFO_SUCCESS';
export const ADMIN_DELETE_GENERAL_INFO_FAILURE =
  'ADMIN_DELETE_GENERAL_INFO_FAILURE';
